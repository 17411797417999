import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useLoginLog } from '@celito.clients/hooks';
import { setAxiosAuthToken } from '@celito.clients/network';
import { logger } from '@celito.clients/services';
import { getHomeUrl } from '@celito.clients/utils';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Loader } from '../../../../../libs/shared/src/lib/loader/src';
import { msalScopes } from '../../authConfig';
import styles from './main.module.css';

const Main: React.FC = () => {
  const { instance: msalInstance } = useMsal();

  const isAuthenticated = useIsAuthenticated();

  const navigate = useNavigate();

  const { postLoginLog } = useLoginLog();

  useEffect(() => {
    if (isAuthenticated) navigate(getHomeUrl());
  }, [isAuthenticated]);

  useEffect(() => {
    handleLoginRedirectResponse();
  }, []);

  const handleLoginRedirectResponse = async () => {
    try {
      const result = await msalInstance.handleRedirectPromise();

      if (result) {
        const account = result.account;

        const tokenRequest = {
          scopes: [msalScopes],
          account: account!,
        };

        const { accessToken } = await msalInstance.acquireTokenSilent(
          tokenRequest
        );

        setAxiosAuthToken(accessToken);

        postLoginLog({
          isSuccessful: true,
          accessToken,
          requestId: result.requestId,
        });

        navigate(getHomeUrl());
      }
    } catch (error) {
      logger.error(error as Error);
      navigate('/error');
    }
  };

  return (
    <div className={styles.container}>
      <Loader withLabel />
    </div>
  );
};

export default Main;
