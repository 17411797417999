import { CompleteTrainingButton, DocumentViewer } from '@celito.clients/shared';
import cn from 'classnames';
import CurrentSectionIcon from 'libs/assets/src/images/current-section.png';
import SuccessSectionIcon from 'libs/assets/src/images/success-section.png';

import {
  DocumentsViewPageViewProps,
  DocumentViewType,
} from './documents-view-page.model';
import classes from './documents-view-page.module.css';

export const DocumentsViewPageView = (props: DocumentsViewPageViewProps) => {
  return (
    <div className={classes.documents_viewer_parent}>
      {props.trainingAssignmentData &&
        props.isHandleDocumentComplete &&
        props.showCompleteButton && (
          <CompleteTrainingButton
            trainingPlanName={props.trainingPlanName}
            trainingAssignmentType={props.trainingAssignmentType}
            trainingAssignmentData={props.trainingAssignmentData}
            openCompleteFlow={props.openCompleteModal}
            completedInCurrentSession={props.isCompletedAllDocuments}
          />
        )}
      <div className={classes.documents_list}>
        {props.documents.map(
          (document: DocumentViewType, documentIndex: number) => (
            <div
              key={document.documentId}
              className={cn(classes.document_name, {
                [classes.completed_document]:
                  props.isDocumentCompleted(document) &&
                  !props.isDocumentActive(document),
                [classes.active_document]: props.isDocumentActive(document),
              })}
              onClick={() =>
                props.handleDocumentsSelect(document, documentIndex)
              }
            >
              <div className={classes.icon}>
                {props.isDocumentCompleted(document) &&
                  !props.isDocumentActive(document) && (
                    <img src={SuccessSectionIcon} alt="Success Icon" />
                  )}

                {props.isDocumentActive(document) && (
                  <img
                    src={CurrentSectionIcon}
                    alt="Active Icon"
                    data-testid="img-active-icon"
                  />
                )}
              </div>
              <span
                className={cn(classes.label, {
                  [classes.completed_document_label]:
                    props.isDocumentCompleted(document) &&
                    !props.isDocumentActive(document),
                })}
              >
                {document.trainingMaterialTitle}
              </span>
            </div>
          )
        )}
      </div>
      <div className={classes.divider}></div>
      <div className={classes.document_display}>
        <DocumentViewer
          documentId={props?.currentDocumentId}
          objectName={props?.currentObjectName}
          trainingMaterialName={props?.trainingMaterialName}
          trainingMaterialVersion={props?.trainingMaterialVersion}
          handleCompleteDocument={props.handleDocumentComplete}
          recordName={props?.trainingMaterialName}
          version={props.trainingAssignmentData?.course?.version}
          hideToolbar
          hideNavigation
          trainingAssignmentData={props.trainingAssignmentData}
        />
      </div>
    </div>
  );
};
