import { performRequest } from '@celito.clients/network';

import Endpoints from '../../config/endpoint';
import { ApiMethodType } from '../../enums/api-constants';
import { LoginType } from '../../types/login-types';

interface IPostLoginLog {
  isSuccessful: boolean;
  requestId?: string;
  accessToken: string;
}

export function useLoginLog() {
  const postLoginLog = async ({
    isSuccessful,
    accessToken,
    requestId,
  }: IPostLoginLog) => {
    const newLoginLog: LoginType = {
      loginLog: { isSuccessful, requestId },
    };

    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    try {
      const response = await performRequest<LoginType>(
        `${Endpoints.LOGIN_LOG}`,
        ApiMethodType.POST,
        newLoginLog,
        { headers }
      );
      return response.data;
    } catch (e) {
      //error handler
    }
  };

  return { postLoginLog };
}
